import React, { useState } from 'react';
import { supabase } from '../utils/SupabaseClient';
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';

const RegisterUser = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [inputRole, setInputRole] = useState('user');
  const [branch, setBranch] = useState('runda');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { user, role } = useAuth();

  if (!user || role !== 'admin') {
    return <div className='text-center mt-28'>
      <div className='mb-2'>You do not have access to this page.</div>
      <Link className='mt-5 bg-red-600 hover:bg-red-400 text-sm text-white rounded-lg p-2' to={"/"}>Go to homepage</Link>
      </div>;
  }

  const registerMiddleware = async (email, password, branch, inputRole = "user") => {
    setLoading(true);
    // Check if email and password are provided
    if (!email || !password) {
      setLoading(false);
      return { error: { message: "Email and password are required" } };
    }
  
    // Attempt to register the user with Supabase
    const { data, error } = await supabase.auth.signUp({
      email,
      password
    });
  
    if (error) {
      setLoading(false);
      return { error };
    }
  
    // Insert profile data into the 'profiles' table
    const { error: profileError } = await supabase
      .from('profiles')
      .insert([{ id: data.user.id, email: data.user.email, branch, role: inputRole }]);
  
    setLoading(false);
    if (profileError) {
      console.error("Error creating profile:", profileError);
      return { error: profileError };
    }
  
    return { message: "User Registration successful." };
  };

  const handleRegister = async () => {
    const success = await registerMiddleware(email, password, branch, inputRole);
    if (success) {
      console.log(success);
      setMessage('Registration successful!');
      setEmail('');
      setPassword('');
      setInputRole('user');
      setBranch('runda');
      setError(false);
    } else {
      setMessage('Registration failed. Please try again.');
      setError(true);
    }
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-white shadow-md rounded-lg mt-28 text-sm">
      <h2 className="text-sm uppercase font-bold mb-4">Add User</h2>
      <input
        type="email"
        placeholder="Enter Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="w-full p-2 border border-gray-300 rounded-md mb-4"
      />
      <input
        type="password"
        placeholder="Enter Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="w-full p-2 border border-gray-300 rounded-md mb-4"
      />
      <div className='mb-2 font-bold'>Branch:</div>
      <select
        value={branch}
        onChange={(e) => setBranch(e.target.value)}
        className="w-full p-2 border border-gray-300 rounded-md mb-4"
      >
        <option value="runda">Runda</option>
        <option value="buffalo">Buffalo</option>
      </select>
      <div className='mb-2 font-bold'>Role:</div>
      <select
        value={inputRole}
        onChange={(e) => setInputRole(e.target.value)}
        className="w-full p-2 border border-gray-300 rounded-md mb-4"
      >
        <option value="user">User</option>
        <option value="admin">Admin</option>
      </select>
      <button
        onClick={(e) => {
          e.preventDefault();
          handleRegister()
        }}
        className="w-full p-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md"
        disabled={loading}
      >
        {loading ? 'Adding User...' : 'Add User'}
      </button>
      {message && <p className={`mt-4 ${error ? 'text-red-600' : 'text-green-800'}`}>{message}</p>}
    </div>
  );
};

export default RegisterUser;
