// AddCustomer.js
import React, { useState } from 'react';
import { supabase } from '../utils/SupabaseClient';
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';

const RegisterSale = () => {

  const { branch } = useAuth();
  const [customerName, setCustomerName] = useState('');
  const [chassisNumbers, setChassisNumbers] = useState(['']);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const { user, role } = useAuth();

  if (!user || role !== 'admin') {
    return (
      <div className='text-center mt-28'>
        <div className='mb-2'>You do not have access to this page.</div>
        <Link className='mt-5 bg-red-600 hover:bg-red-400 text-sm text-white rounded-lg p-2' to={"/"}>
          Go to homepage
        </Link>
      </div>
    );
  }

  // Handle adding a customer and chassis numbers
  const handleAddCustomer = async () => {
    setLoading(true);
    setMessage('');

    if(branch == "superadmin"){
      if(selectedBranch == null || selectedBranch.length < 1){
        setMessage('Please Select Branch');
        setError(true);
        setLoading(false);
        return
      }
    }

    if (!customerName || chassisNumbers.some(chassis => !chassis)) {
      setMessage('Please enter the customer name and all chassis numbers.');
      setError(true);
      setLoading(false);
      return;
    }

    

    // Insert each chassis number as a separate record
    for (let chassisNumber of chassisNumbers) {
      // Check if the chassis number already exists in the system
      const { data: existingChassis, error: fetchError } = await supabase
        .from('vehicles')
        .select('chassis_number')
        .eq('chassis_number', chassisNumber)
        .single();

      if (fetchError && fetchError.code !== 'PGRST116') {
        setMessage('An error occurred while checking the chassis number.');
        setError(true);
        setLoading(false);
        return;
      }

      if (existingChassis) {
        setMessage(`Chassis number ${chassisNumber} already exists in the system.`);
        setError(true);
        setLoading(false);
        return;
      }

      if(branch == "superadmin")
      {
        // Insert the new customer into the vehicles table
        const { error } = await supabase.from('vehicles').insert([
          {
            branch: selectedBranch,
            customer_name: customerName,
            chassis_number: chassisNumber
          }
        ]);

        if (error) {
          setMessage('An error occurred while adding the customer.');
          setError(true);
          setLoading(false);
          return;
        }
      }else{
        const { error } = await supabase.from('vehicles').insert([
          {
            branch: branch,
            customer_name: customerName,
            chassis_number: chassisNumber
          }
        ]);

        if (error) {
          setMessage('An error occurred while adding the customer.');
          setError(true);
          setLoading(false);
          return;
        }
      }     

      
    }

    setMessage('Customer and chassis numbers added successfully.');
    setCustomerName('');
    setChassisNumbers(['']);
    setError(false);
    setLoading(false);
  };

  // Add a new chassis number input field
  const addChassisInput = () => {
    setChassisNumbers([...chassisNumbers, '']);
  };

  // Update a specific chassis number in the list
  const updateChassisNumber = (index, value) => {
    const newChassisNumbers = [...chassisNumbers];
    newChassisNumbers[index] = value;
    setChassisNumbers(newChassisNumbers);
  };

  // Remove a specific chassis number input field
  const removeChassisInput = (index) => {
    if (chassisNumbers.length > 1) {
      const newChassisNumbers = chassisNumbers.filter((_, i) => i !== index);
      setChassisNumbers(newChassisNumbers);
    }
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-white shadow-md rounded-lg text-sm mt-28">
      <h2 className="text-sm uppercase font-bold mb-4">Record Sale</h2>
      <div className='mb-2 font-bold'>Branch:</div>
      { branch == "runda" 
        ? 
          <div className='w-full p-2 border border-gray-300 rounded-md mb-4'>Runda</div>
        : 
        branch == "buffalo" 
        ?
          <div className='w-full p-2 border border-gray-300 rounded-md mb-4'>Bufallo</div>
        :
          <select
            value={selectedBranch}
            className="w-full p-2 border border-gray-300 rounded-md mb-4"
            onChange={e => setSelectedBranch(e.target.value)}
          >
            <option value={null}></option>
            <option value="runda">Runda</option>
            <option value="buffalo">Buffalo</option>
          </select> 
      }
      <input
        type="text"
        placeholder="Enter Customer Name"
        value={customerName}
        onChange={(e) => setCustomerName(e.target.value)}
        className="w-full p-2 border border-gray-300 rounded-md mb-4"
      />
      {chassisNumbers.map((chassis, index) => (
        <div key={index} className="flex mb-4 items-center">
          <input
            type="text"
            placeholder={`Enter Chassis Number ${index + 1}`}
            value={chassis}
            onChange={(e) => updateChassisNumber(index, e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          <button
            type="button"
            onClick={() => removeChassisInput(index)}
            className="ml-1 text-red-500 flex items-center justify-center p-1 text-sm"
            disabled={chassisNumbers.length === 1} // Disable if it's the last input
          >
            X
          </button>
        </div>
      ))}
      <button
        type="button"
        onClick={addChassisInput}
        className="bg-blue-500 hover:bg-blue-600 text-white p-1 rounded-md mb-4 text-xs"
      >
        + Add Another Chassis Number
      </button>
      {!loading && (
        <button
          onClick={(e) => {
            e.preventDefault();
            handleAddCustomer();
          }}
          className="w-full p-2 bg-orange-400 hover:bg-orange-500 text-white rounded-md"
        >
          Record Sale
        </button>
      )}
      {loading && (
        <button
          onClick={(e) => {
            e.preventDefault();
          }}
          className="w-full p-2 bg-zinc-400 hover:bg-zinc-500 text-white rounded-md"
        >
          Loading ....
        </button>
      )}
      {message && (
        <p className={`mt-4 ${error ? 'text-red-500' : 'text-green-500'} `}>{message}</p>
      )}
    </div>
  );
};

export default RegisterSale;
