// AdminDashboard.js
import React from 'react';
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import PlayHistory from './PlayHistory';

const AdminDashboard = () => {
  const { user, role, branch } = useAuth();

  if (!user || role !== 'admin') {
    return <div className='text-center mt-28'>
      <div className='mb-2'>You do not have access to this page.</div>
      <Link className='mt-5 bg-red-600 hover:bg-red-400 text-sm text-white rounded-lg p-2' to={"/"}>Go to homepage</Link>
      </div>;
  }

  return (
    <div className="p-4 mt-10 lg:mt-20 text-sm lg:mx-16">
      <h1 className="uppercase font-bold mb-4">Admin Dashboard</h1>
      <div className="mb-4 flex gap-4 items-center">
        <Link to={"/register_sale"} className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600">
          Record Sale
        </Link>
        { branch == "superadmin" && <Link to="/register">Add User</Link> }
      </div>
      <div className="mt-8">
        <h2 className="text-sm uppercase font-bold">Open Tickets and Play History</h2>
        <PlayHistory />
      </div>
    </div>
  );
};

export default AdminDashboard;
