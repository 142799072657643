import React, { createContext, useState, useContext, useEffect } from 'react';
import { supabase } from '../utils/SupabaseClient';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const [branch, setBranch] = useState(null);

  const isLoggedIn = async () =>{
      let savedEmail = localStorage.getItem('$lucky_user');
      let savedRole = localStorage.getItem('$lucky_role');
      let savedBranch = localStorage.getItem('$lucky_branch');


      if(savedEmail){          
          setUser(savedEmail);
      }

      if(savedRole){          
        setRole(savedRole)
      }

      if(savedBranch){          
        setBranch(savedBranch)
      }
  }

  useEffect(()=>{
      isLoggedIn();
  },[])

  const login = (user, role, branch) =>{
      setUser(user);
      setRole(role);
      setBranch(branch);
      localStorage.setItem('$lucky_user', user)
      localStorage.setItem('$lucky_role', role)
      localStorage.setItem('$lucky_branch', branch)
  }

  const logout = async () => {
    await supabase.auth.signOut();
    localStorage.removeItem('$lucky_user', user)
    localStorage.removeItem('$lucky_role', role)
    localStorage.removeItem('$lucky_branch', branch)
    setUser(null);
    setRole(null);
    setBranch(null);
  };

  return (
    <AuthContext.Provider value={{ user, role, branch, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);