import { Link, Route, Routes, Navigate, useNavigate } from "react-router-dom";
import LuckyDraw from "./components/LuckyDraw";
import RegisterSale from "./components/RegisterSale";
import AdminDashboard from "./components/AdminDashboard";
import Login from "./components/Login";
import RegisterUser from "./components/RegisterUser";
import naviLogo from './asssets/navi-logo.png';
import Page404 from "./components/Page404";
import { useAuth } from "./context/AuthContext";

function App() {
  const { user, logout, role, branch } = useAuth();

  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login'); // Redirect to the login page after logging out
  }

  return (
    <div className="App min-h-screen bg-gray-100">
      <div className="p-4">
        <div id="header" className="mb-4 flex items-center gap-4 justify-between mx-1 lg:mx-20">
          <Link to="/" className="mr-4">
            <img src={naviLogo} className="w-14 lg:w-24" alt="Navi Logo" />
          </Link>
          <div className="w-3/4 flex justify-end gap-2 lg:gap-5 items-center text-xs lg:text-base">
            {user ? (
              <>
                { role === 'admin' ? (
                  <>
                    <Link to="/admin-dashboard" className="hover:text-orange-400 uppercase">Dashboard</Link>
                    <Link to="/register_sale" className="hover:text-orange-400 uppercase">Record Sale</Link>
                  </>
                ) : (
                  <>
                    <Link to="#" className="mr-10 capitalize">{branch} Branch</Link>
                    <Link to="/" className="hover:text-orange-400 uppercase">Play Lucky Draw</Link>
                  </>
                )}
                <button onClick={(e) => {
                  e.preventDefault();
                  handleLogout()
                  }} className="uppercase bg-red-600 hover:bg-red-400 rounded-lg px-2 p-1 text-white">Logout</button>
              </>
            ) : (
              <>
                <Link to="/login" className="hover:text-orange-400  uppercase">Login</Link>
              </>
            )}
          </div>
        </div>
        <Routes>
          <Route path="/" element={ !user ? <Login /> : role == "admin" ? <AdminDashboard /> : <LuckyDraw />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/register_sale" element={<RegisterSale /> } />
          <Route path="/lucky-draw" element={ <LuckyDraw />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<RegisterUser />} />
          <Route path="/*" element={<Page404 />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
