import React from 'react'
import { Link } from 'react-router-dom'

function Page404() {
  return (
    <div className='text-center mt-28'>
      <div className='text-5xl font-bold mb-5'>404</div>
      <div className='mb-5'>This page does not exist</div>
      <Link className='mt-10 bg-red-600 hover:bg-red-400 text-sm text-white rounded-lg p-2' to={"/"}>Go to homepage</Link>
    </div>
  )
}

export default Page404
