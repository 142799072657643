import React, { useState } from 'react';
import { supabase } from '../utils/SupabaseClient';
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import Confetti from 'react-confetti';

const LuckyDraw = () => {
  const [chassisNumber, setChassisNumber] = useState('');
  const [message, setMessage] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [award, setAward] = useState(null);
  const [error, setError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const { user, branch } = useAuth();

  if (!user) {
    return (
      <div className='text-center mt-28'>
        <div className='mb-2'>You do not have access to this page.</div>
        <Link className='mt-5 bg-red-600 hover:bg-red-400 text-sm text-white rounded-lg p-2' to={"/"}>Go to homepage</Link>
      </div>
    );
  }

  // Fetch awards from the database
  const getAwards = async () => {
    const { data, error } = await supabase.from('awards').select('*');
    if (error) {
      console.error('Error fetching awards:', error);
      return [];
    }
    return data;
  };

  // Randomly select an award based on probabilities
  const selectAward = (awards) => {
    const totalProbability = awards.reduce((sum, award) => sum + award.probability, 0);
    let random = Math.random() * totalProbability;
    for (let award of awards) {
      if (random < award.probability) {
        return award.name;
      }
      random -= award.probability;
    }
    return null;
  };

  // Handle Lucky Draw
  const handleLuckyDraw = async () => {
    setLoading(true);
    setMessage('');
    setAward(null);
    setCustomerName('');
    setShowModal(false);

    // Fetch the customer name using the chassis number
    const { data: vehicle, error: vehicleError } = await supabase
      .from('vehicles')
      .select('customer_name, branch')
      .eq('chassis_number', chassisNumber)
      .single();

    if (vehicleError || !vehicle) {
      setMessage('Invalid chassis number.');
      setError(true);
      setLoading(false);
      return;
    }

    const vehicleData = vehicle;

    if (branch !== vehicleData.branch) {
      setMessage(`This client is set to play at ${vehicleData.branch}`);
      setError(true);
      setLoading(false);
      return;
    }

    setCustomerName(vehicleData.customer_name);

    // Check if the chassis number has already been used
    const { data: existingEntry, error: entryError } = await supabase
      .from('lucky_draw_entries')
      .select('*')
      .eq('chassis_number', chassisNumber)
      .single();

    if (entryError && entryError.code !== 'PGRST116') {
      setMessage('An error occurred while checking the chassis number.');
      setError(true);
      setLoading(false);
      return;
    }

    if (existingEntry) {
      setMessage('This chassis number has already been used. <br /> Try Again with another chasis number!');
      setError(true);
      setLoading(false);
      return;
    }

    // Get the list of awards
    const awards = await getAwards();
    if (!awards.length) {
      setMessage('No awards available.');
      setError(true);
      setLoading(false);
      return;
    }

    // Select an award
    const selectedAward = selectAward(awards);
    setAward(selectedAward);

    // Record the Lucky Draw entry
    const { error: insertError } = await supabase.from('lucky_draw_entries').insert([
      {
        chassis_number: chassisNumber,
        customer_name: vehicleData.customer_name,
        branch : branch,
        played_at: new Date().toISOString(),
        award: selectedAward
      }
    ]);

    if (insertError) {
      setMessage('An error occurred while recording the draw.');
      setLoading(false);
    } else {
      setMessage(`Congratulations! You have won an award.`);
      setError(false);
      setShowModal(true); // Show the modal when an award is won
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-white shadow-md rounded-lg mt-28 text-sm">
      <h2 className="uppercase font-bold mb-4">Play Lucky Draw</h2>
      <input
        type="text"
        placeholder="Enter Chassis Number"
        value={chassisNumber}
        onChange={(e) => setChassisNumber(e.target.value)}
        className="w-full p-2 border border-gray-300 rounded-md mb-4"
      />
      { !loading && <button
        onClick={handleLuckyDraw}
        className="w-full p-2 bg-blue-500 text-white rounded-md"
      >
        Play Lucky Draw
      </button> }
      { loading && <button
        onClick={(e)=>{
          e.preventDefault();
        }}
        className="w-full p-2 bg-zinc-500 text-white rounded-md"
      >
        Loading ...
      </button> }
      {message && (
        <p
          className={`mt-4 ${error ? 'text-red-500' : 'text-green-500'}`}
          dangerouslySetInnerHTML={{ __html: message }}
        ></p>
      )}
      
      {/* Show confetti when the modal is open */}
      {showModal && <Confetti width={window.innerWidth} height={window.innerHeight} />}

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center z-50 w-1/2">
            <h3 className="text-xl font-bold mb-2">Congratulations!</h3>
            <p className="mb-4 text-lg mt-5"><b className='text-xl capitalize font-mono'>{customerName}</b> of truck with chasis no. <b className='text-xl font-mono'>{chassisNumber}</b> has won:</p>
            <p className="text-4xl tracking-wide uppercase text-orange-500"><b>{award}</b></p>

            { 
              award == "100L Fuel" ?
              <div className='flex justify-center'>
                <img src={require('../asssets/awards/100lfuel.png')} style={{ width: '600px'}} />
              </div> 
              :
              award == "One time maintenance" ?  
              <div className='flex justify-center my-10'>
                <img src={require('../asssets/awards/onemaintain.jpg')} style={{ width: '600px'}} />
              </div> 
              :
              award == "200L Fuel" ? 
              <div className='flex justify-center my-10'>
                <img src={require('../asssets/awards/200lfuel.png')} className='w-96' />
              </div>
              :
              award == "300L Fuel" ? 
              <div className='flex justify-center my-10'>
                <img src={require('../asssets/awards/300lfuel.png')} className='w-96' />
              </div> 
              :
              award == "GPS" ? 
              <div className='flex justify-center my-10'>
                <img src={require('../asssets/awards/gps.png')} className='w-96' />
              </div> 
              : 
              award == "2 times maintenance" ?
              <div className='flex justify-center my-10'>
                <img src={require('../asssets/awards/twomaintenance.png')}  style={{ width: '660px'}} />
              </div> 
              :
              null      
            } 
            <button
              className="mt-4 bg-blue-500 text-white p-2 rounded-lg"
              onClick={() => setShowModal(false)}
            >
              Close
            </button>
          </div>
          <div className="fixed inset-0 bg-black opacity-50" onClick={() => setShowModal(false)}></div>
        </div>
      )}
    </div>
  );
};

export default LuckyDraw;
