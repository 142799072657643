import React, { useEffect, useState } from 'react';
import { supabase } from '../utils/SupabaseClient';
import { useAuth } from '../context/AuthContext';

function PlayHistory() {
  const { branch } = useAuth();
  const [plays, setPlays] = useState([]);
  const [unplayedTrucks, setUnplayedTrucks] = useState([]);
  const [search, setSearch] = useState(null);

  useEffect(() => {
    const getPlaysAndUnplayedTrucks = async () => {
      // Fetch lucky draw entries
      const { data: drawData, error: drawError } = await supabase
        .from('lucky_draw_entries')
        .select('*')

      // Fetch all vehicles
      const { data: vehiclesData, error: vehiclesError } = await supabase
        .from('vehicles')
        .select('*')

      if (drawError || vehiclesError) {
        console.error('Error fetching data:', drawError || vehiclesError);
      } else {
        setPlays(drawData);

        // Filter vehicles that haven't been played in the lucky draw
        const unplayed = vehiclesData.filter(
          (vehicle) =>
            !drawData.some(
              (draw) => draw.chassis_number == vehicle.chassis_number
            )
        );

        setUnplayedTrucks(unplayed);
      }
    };

    getPlaysAndUnplayedTrucks();
  }, []);

  const filteredData = plays.filter((item) => {
    if (search === '' || search === null) {
      return item;
    } else if (
      item.customer_name.toLowerCase().includes(search.toLowerCase()) ||
      item.chassis_number.toLowerCase().includes(search.toLowerCase()) 
    ) {
      return item;
    }
  });

  const filteredUnplayedTrucks = unplayedTrucks.filter((item) => {
    if (search === '' || search === null) {
      return item;
    } else if (
      item.customer_name.toLowerCase().includes(search.toLowerCase()) ||
      item.chassis_number.toLowerCase().includes(search.toLowerCase())
    ) {
      return item;
    }
  });

  const deletePlay = async (chassis_number) => {
    const { error } = await supabase
      .from('lucky_draw_entries')
      .delete()
      .eq('chassis_number', chassis_number);

      const { newError } = await supabase
      .from('vehicles')
      .delete()
      .eq('chassis_number', chassis_number);

    if (error) {
      console.error('Error deleting play:', error);
    } else {
      setPlays(plays.filter((play) => play.chassis_number !== chassis_number));
    }
  };

  const deleteUnplayedTruck = async (chassis_number) => {
    const { error } = await supabase
      .from('vehicles')
      .delete()
      .eq('chassis_number', chassis_number);

    if (error) {
      console.error('Error deleting unplayed truck:', error);
    } else {
      setUnplayedTrucks(
        unplayedTrucks.filter((truck) => truck.chassis_number !== chassis_number)
      );
    }
  };

  return (
    <div className='mt-5'>
      <div className="flex mr-0 lg:mr-5 mb-3 justify-end">
        <input
          type="text"
          placeholder='Search ...'
          className='p-1 lg:p-2 rounded-lg bg-white border border-gray-600 text-black dark:text-gray-500'
          onChange={(e) =>
            e.target.value === '' ? setSearch(null) : setSearch(e.target.value)
          }
        />
      </div>
      <div class="relative overflow-x-auto text-sm lg:text-base">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-2 lg:px-6 py-3">Branch</th>
              <th scope="col" class="px-2 lg:px-6 py-3">Customer Name</th>
              <th scope="col" class="px-2 lg:px-6 py-3">Chassis Number</th>
              <th scope="col" class="px-2 lg:px-6 py-3">Award</th>
              <th scope="col" class="px-2 lg:px-6 py-3">Date</th>
              <th scope="col" class="px-2 lg:px-6 py-3">Status</th>
              <th scope="col" class="px-2 lg:px-6 py-3">Action</th>
            </tr>
          </thead>
          <tbody>
            {/* Display all played vehicles */}
            {filteredData.length > 0 &&
              filteredData.filter(item => {
                if(item.branch == branch){
                  return item
                }else if(branch == "superadmin"){
                  return item;
                }
              }
              ).map((play, index) =>{ 
                console.log(play)
                return (
                <tr
                  key={index}
                  class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <th scope="row" class="px-2 lg:px-6 py-4 font-medium text-gray-900 capitalize whitespace-nowrap dark:text-white">
                    {play.branch}
                  </th>
                  <th scope="row" class="px-2 lg:px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {play.customer_name}
                  </th>
                  <td class="px-2 lg:px-6 py-4">{play.chassis_number}</td>
                  <td class="px-2 lg:px-6 py-4">{play.award}</td>
                  <td class="px-2 lg:px-6 py-4">
                    {new Date(play.played_at).toLocaleString()}
                  </td>
                  <td class="px-2 lg:px-6 py-4">
                    <span className='bg-green-700 p-1 px-2 text-white rounded-lg text-xs'>Played</span>
                  </td> {/* No Award */}
                  <td class="px-2 lg:px-6 py-4">
                    <button
                      className="bg-slate-500 text-white p-1 rounded-lg text-xs"
                      onClick={(e) => e.preventDefault()}
                    >
                      No Action
                    </button>
                  </td>
                </tr>
              )
            }
              )}

            {/* Display unplayed vehicles */}
            {filteredUnplayedTrucks.length > 0 &&
              filteredUnplayedTrucks.filter(item => {
                if(item.branch == branch){
                  return item
                }else if(branch == "superadmin"){
                  return item;
                }
              }
              ).map((truck, index) => (
                <tr
                  key={index}
                  class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <th scope="row" class="px-2 lg:px-6 py-4 font-medium text-gray-900 capitalize whitespace-nowrap dark:text-white">
                    {truck.branch}
                  </th>
                  <th scope="row" class="px-2 lg:px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {truck.customer_name}
                  </th>
                  <td class="px-2 lg:px-6 py-4">{truck.chassis_number}</td>
                  <td class="px-2 lg:px-6 py-4">
                    <span className='bg-orange-500 p-1 text-white rounded-lg text-xs'>Not Played</span>
                  </td> {/* No Award */}
                  <td class="px-2 lg:px-6 py-4">-</td> {/* No Date */}
                  <td class="px-2 lg:px-6 py-4">
                    <span className='bg-orange-500 p-1 text-white rounded-lg text-xs'>Not Played</span>
                  </td> {/* No Award */}
                  <td class="px-2 lg:px-6 py-4">
                    <button
                      className="bg-red-500 text-white p-1 rounded-lg text-xs"
                      onClick={() => deleteUnplayedTruck(truck.chassis_number)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}

            {/* No records found */}
            {filteredData.length < 1 && filteredUnplayedTrucks.length < 1 && (
              <tr>
                <td colSpan={6} className='p-4 text-sm: lg:text-base'>
                  No play history found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PlayHistory;
