import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../utils/SupabaseClient';
import { useAuth } from '../context/AuthContext';

const Login = () => {
  const { user, login, logout } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const loginMiddleware = async (email, password) => {
    setLoading(true);
    
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password
    });
  
    setLoading(false);
    if (error) {
      console.log(error);
      return { error };
    }
  
    // If login is successful, return the user data
    return { user: data.user };
  };

  const handleLogin = async () => {

    if (!email || !password) {
      setLoading(false);
      setError(true);
      setMessage("Email and password are required")
      return ;
    }


    const { user: loggedInUser, error } = await loginMiddleware(email, password);

    if (!error && loggedInUser) {
      setMessage('Login successful!');
      setError(false);

      const { data: profileData, error: profileError } = await supabase
            .from('profiles')
            .select('role, branch')
            .eq('id', loggedInUser.id)
            .single();

        if (!profileError) {
          loggedInUser.role = profileData.role;
          loggedInUser.branch = profileData.branch;
        }
        
        login(loggedInUser.email, loggedInUser.role, loggedInUser.branch);
  
      // Redirect based on the user's role
      if (loggedInUser.role === 'admin') {
        navigate('/admin-dashboard');
      } else {
        navigate('/lucky-draw');
      }
    } else {
      setMessage('Login failed. Please check your credentials.');
      setError(true);
    }
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-white shadow-md rounded-lg mt-28 text-sm">
      <h2 className="uppercase font-bold mb-4">Login</h2>
      <form>
      <input
        type="email"
        placeholder="Enter Email"
        onChange={(e) => setEmail(e.target.value)}
        className="w-full p-2 border border-gray-300 rounded-md mb-4"
        required
      />
      <input
        type="password"
        placeholder="Enter Password"
        onChange={(e) => setPassword(e.target.value)}
        className="w-full p-2 border border-gray-300 rounded-md mb-4"
        required
      />
      <button
        type='submit'
        onClick={(e) => {
          e.preventDefault();
          handleLogin()
        }}
        className="w-full p-2 bg-blue-500 text-white rounded-md"
        disabled={loading}
      >
        {loading ? 'Logging in...' : 'Login'}
      </button>
      </form>
      {message && <p className={`mt-4 ${error ? 'text-red-600' : 'text-green-800'}`}>{message}</p>}
    </div>
  );
};

export default Login;
